/**
 * In this file we keep a mapping of all modules and their properties:
 * - id
 * - key
 * - properties
 * - component (derived from key if none is provided)
 * - label (derived from key if none is provided)
 * - path (derived from key if none is provided)
 * - product (derived from key if none is provided)
 * - name (derived from key if none is provided)
 *
 * These properties are extensively used throughout the project.
 * By providing a single source of truth we aim to reduce the amount of bugs.
 */
/**
 * A number of modules/components have been disabled as they are not ready for release 3.2.0
 */

// A
// B
export const BOND_INFO = create({
  id: 19,
  key: 'bondInfo',
  desc: 'Bond information',
});

export const BOND_QUOTE = create({
  id: 20,
  key: 'bondQuote',
  desc: 'Bond quote',
});

export const BROKER_STATS = create({
  id: 1,
  key: 'brokerStats',
  properties: [
    {
      key: 'numberOfBrokers',
      value: '10',
    },
  ],
  json: true,
  desc: 'Buying and selling brokers, within specified periods',
});

// C
export const COMPANY_DISCLOSURES = create({
  id: 2,
  key: 'companyDisclosures',
  properties: [
    {
      key: 'numberOfDisclosures',
      value: '10',
    },
    {
      key: 'pageSize',
      value: '10',
    },
  ],
  json: true,
  jsonOptions: [
    {
      param: 'lang',
      type: ['en', 'no'],
      value: 'en',
      required: false,
    },
  ],
  desc: 'Latest company news',
});

export const COMPANY_DISCLOSURES_ARCHIVE = create({
  id: 3,
  key: 'companyDisclosuresArchive',
  properties: [
    {
      key: 'pageSize',
      value: '10',
    },
  ],
  json: true,
  jsonOptions: [
    {
      param: 'start',
      type: 'unix time',
      value: Date.now() - 2629800000,
      required: true,
    },
    {
      param: 'stop',
      value: Date.now(),
      type: 'unix time',
      required: true,
    },
    { param: 'headline', type: 'string', value: undefined, required: false },
  ],
  desc: 'Search and display company news',
});

/** @deprecated */
export const COMPANY_DISCLOSURES_RSS = create({
  id: 4,
  key: 'companyDisclosuresRSS',
  properties: [
    {
      key: 'numberOfDisclosures',
      value: '10',
    },
  ],
  desc: 'Latest company news as RSS',
});

export const COMPANY_INFO = create({
  id: 5,
  key: 'companyInfo',
  properties: [
    {
      key: 'postlude_en',
    },
    {
      key: 'postlude_no',
    },
    {
      key: 'prelude_en',
    },
    {
      key: 'prelude_no',
    },
  ],
  json: true,
  desc: 'Basic company information',
});

// D
/** @deprecated */
export const DEMOGRAPHICS_ACCOUNT_TYPE = create({
  id: 942,
  key: 'demographicsAccountType',
  properties: [
    {
      key: 'background',
      value: '0xffffff',
    },
    {
      key: 'colors',
      value:
        '0x7ca689,0xf8db60,0x879ace,0x8f3f97,0xfca55c,0x87b3ce,0x704097,0xf7f13c,0x000000,0xffffff',
    },
    {
      key: 'count',
      value: '10',
    },
    {
      key: 'height',
      value: '400',
    },
    {
      key: 'investors',
      value: 'false',
    },
    {
      key: 'legend',
      value: 'true',
    },
    {
      key: 'presentation',
      value: 'table',
    },
    {
      key: 'shadow',
      value: '0xcccccc',
    },
    {
      key: 'width',
      value: '500',
    },
  ],
});

/**   @deprecated */
export const DEMOGRAPHICS_ORIGIN_COUNTRY = create({
  id: 943,
  key: 'demographicsOriginCountry',
  properties: [
    {
      key: 'background',
      value: '0xffffff',
    },
    {
      key: 'colors',
      value:
        '0x7ca689,0xf8db60,0x879ace,0x8f3f97,0xfca55c,0x87b3ce,0x704097,0xf7f13c,0x000000,0xffffff',
    },
    {
      key: 'count',
      value: '10',
    },
    {
      key: 'height',
      value: '400',
    },
    {
      key: 'investors',
      value: 'false',
    },
    {
      key: 'legend',
      value: 'true',
    },
    {
      key: 'presentation',
      value: 'table',
    },
    {
      key: 'shadow',
      value: '0xcccccc',
    },
    {
      key: 'width',
      value: '500',
    },
  ],
});

/** @deprecated */
export const DEMOGRAPHICS_TAX_COUNTRY = create({
  id: 944,
  key: 'demographicsTaxCountry',
  properties: [
    {
      key: 'background',
      value: '0xffffff',
    },
    {
      key: 'colors',
      value:
        '0x7ca689,0xf8db60,0x879ace,0x8f3f97,0xfca55c,0x87b3ce,0x704097,0xf7f13c,0x000000,0xffffff',
    },
    {
      key: 'count',
      value: '10',
    },
    {
      key: 'height',
      value: '400',
    },
    {
      key: 'investors',
      value: 'false',
    },
    {
      key: 'legend',
      value: 'true',
    },
    {
      key: 'presentation',
      value: 'table',
    },
    {
      key: 'shadow',
      value: '0xcccccc',
    },
    {
      key: 'width',
      value: '500',
    },
  ],
});

export const DISCLAIMER = create({
  id: 6,
  key: 'disclaimer',
  desc: 'About this delivery',
});

export const DISCLAIMER_BOND = create({
  id: 18,
  key: 'disclaimerBond',
  desc: 'About this delivery',
});

export const DISCLAIMER_SHAREHOLDERS = create({
  id: 947,
  key: 'disclaimerShareholders',
  desc: 'About this delivery',
});

export const DIVIDEND_HISTORY = create({
  id: 946,
  key: 'dividendHistory',
  json: true,
  desc: 'Dividend History',
});

// E
// F
export const FINANCIAL_CALENDAR = create({
  id: 941,
  key: 'financialCalendar',
  json: true,
  jsonOptions: [
    {
      param: 'start',
      type: 'unix time',
      value: Date.now(),
      required: true,
    },
  ],
  desc: 'Calendar holding financial events',
});

// G
/** @deprecated */
export const GENERIC_SNAPSHOTS = create({
  id: 101,
  key: 'genericSnapshots',
  properties: [
    {
      key: 'items',
    },
  ],
  json: true,
  desc: 'Deliver generic snapshots',
});

/** @deprecated */
export const GRAPH_IMAGE = create({
  id: 7,
  key: 'graphImage',
  label: 'Graph image',
  properties: [
    {
      key: 'compare',
      value: 'feed.ose.index.INDICES:OSEBX',
    },
  ],
  desc:
    'View history of this company as an image, may have a number of display methods and comparations',
});

export const GRAPH_INTERACTIVE = create({
  id: 8,
  key: 'graphInteractive',
  label: 'Graph interactive',
  properties: [
    {
      key: 'chartBackgroundPaint',
      value: '0xffffff',
    },
    {
      key: 'color1',
      value: '0xffb200',
    },
    {
      key: 'color2',
      value: '0xe8e8e8',
    },
    {
      key: 'compare',
      value: 'feed.ose.index.INDICES:OSEBX',
    },
    {
      key: 'height',
      value: '250',
    },
    {
      key: 'plotBackgroundPaint',
      value: '0xffffff',
    },
    {
      key: 'width',
      value: '500',
    },
  ],
  desc: 'History of this company as an interactive object',
});

// H
/** @deprecated */
export const HISTORIC_PROFIT_LONG = create({
  id: 9,
  key: 'historicalProfitLong',
  label: 'Historical Profit Long',
  desc: 'Long term summarizing company numbers',
});

/** @deprecated */
export const HISTORIC_PROFIT_SHORT = create({
  id: 10,
  key: 'historicalProfitShort',
  desc: 'Short term summarizing company numbers',
});

export const HISTORY_EXPORT = create({
  id: 11,
  key: 'historyExport',
  json: true,
  jsonOptions: [
    {
      param: 'start',
      type: 'unix time',
      value: Date.now() - 2629800000,
      required: true,
    },
    {
      param: 'stop',
      value: Date.now(),
      type: 'unix time',
      required: true,
    },
  ],
  desc: 'Export of history to file',
});

// I
// J
// K
// L
// M
/** @deprecated */
export const MINI_GRAPH_INTERACTIVE = create({
  id: 21,
  key: 'miniGraphInteractive',
  properties: [
    {
      key: 'graphLineColor',
      value: '#4572A7',
    },
    {
      key: 'graphFillStartColor',
      value: 'rgba(125,180,225,0.863)',
    },
    {
      key: 'graphFillStopColor',
      value: 'rgba(125,180,225,0.275)',
    },
    {
      key: 'graphColumnColor',
      value: 'rgba(30,146,207,0.5)',
    },
  ],
});

// N
// O
export const ORDER_DEPTH = create({
  id: 12,
  key: 'orderDepth',
  properties: [
    {
      key: 'depth',
      value: '5',
    },
  ],
  json: true,
  desc: 'Current order depth on current company',
});

// P
export const PEER_GROUP_BENCHMARK = create({
  id: 945,
  key: 'peerGroupBenchmark',
  properties: [
    {
      key: 'fieldList',
      value: 'W,M,3M,6M,1Y,2Y',
    },
    {
      key: 'instruments',
    },
    {
      key: 'longnames',
      value: 'false',
    },
  ],
  json: true,
  desc: 'Benchmark company against selected peer companies',
});

export const PROFIT_CALCULATOR = create({
  id: 13,
  key: 'profitCalculator',
  properties: [
    {
      key: 'historyStartDate',
      value: '2003-06-16',
    },
  ],
  desc: 'Simple calculator comparing current pricing with historical pricing',
});

// Q
// R
// S
export const SHARE_PERFORMANCE = create({
  id: 301,
  key: 'sharePerformance',
  json: true,
  desc: 'Summarizing share performance',
});

export const SHAREHOLDERS = create({
  id: 14,
  key: 'shareholders',
  properties: [
    {
      key: 'count',
      value: '20',
    },
  ],
  json: true,
  desc: 'The major shareholders of this company',
});

/** @deprecated */
export const SHAREHOLDERS_COUNTRY = create({
  id: 15,
  key: 'shareholdersCountry',
  properties: [
    {
      key: 'background',
      value: '0xffffff',
    },
    {
      key: 'colors',
      value:
        '0x7ca689,0xf8db60,0x879ace,0x8f3f97,0xfca55c,0x87b3ce,0x704097,0xf7f13c,0x000000,0xffffff',
    },
    {
      key: 'count',
      value: '20',
    },
    {
      key: 'height',
      value: '400',
    },
    {
      key: 'shadow',
      value: '0xcccccc',
    },
    {
      key: 'width',
      value: '500',
    },
  ],
});

export const SNAPSHOT = create({
  id: 16,
  key: 'snapshot',
  json: true,
  desc: 'Current quote information',
});

export const STANDARD_PAGE = create({
  id: 1000,
  key: 'standardPage',
  properties: [
    {
      key: 'graphLineColor',
      value: '#4572A7',
    },
    {
      key: 'graphFillStartColor',
      value: 'rgba(125,180,225,0.863)',
    },
    {
      key: 'graphFillStopColor',
      value: 'rgba(125,180,225,0.275)',
    },
    {
      key: 'graphColumnColor',
      value: 'rgba(30,146,207,0.5)',
    },
    {
      key: 'buttonBackgroundColor',
      value: 'rgba(0,109,204,1)',
    },
    {
      key: 'buttonBackgroundHoverColor',
      value: 'rgba(0,128,204,1)',
    },
    {
      key: 'buttonTextColor',
      value: 'rgba(255,255,255,1)',
    },
  ],
  desc: 'Standard Page',
});

// T
export const TRADES = create({
  id: 17,
  key: 'trades',
  properties: [
    {
      key: 'numberOfTrades',
      value: '20',
    },
  ],
  json: true,
  desc: 'Last trades on this company',
});

// U
// V
// W
// X
// Y
// Z

/**
 * @deprecated
 */
export const GENERAL_THEME = create({
  id: 200,
  key: 'generalTheme',
  properties: [
    {
      key: 'numberOfBrokers',
      value: '10',
    },
  ],
});

export const all = {
  //BROKER_STATS,
  COMPANY_DISCLOSURES,
  COMPANY_DISCLOSURES_ARCHIVE,
  //COMPANY_DISCLOSURES_RSS,
  COMPANY_INFO,
  DISCLAIMER,
  //GRAPH_IMAGE,
  GRAPH_INTERACTIVE,
  //HISTORIC_PROFIT_LONG,
  //HISTORIC_PROFIT_SHORT,
  HISTORY_EXPORT,
  ORDER_DEPTH,
  PROFIT_CALCULATOR,
  SHAREHOLDERS,
  //SHAREHOLDERS_COUNTRY,
  SNAPSHOT,
  TRADES,
  //DISCLAIMER_BOND,
  //BOND_INFO,
  //BOND_QUOTE,
  //MINI_GRAPH_INTERACTIVE,
  //GENERIC_SNAPSHOTS,
  SHARE_PERFORMANCE,
  FINANCIAL_CALENDAR,
  //DEMOGRAPHICS_ACCOUNT_TYPE,
  //DEMOGRAPHICS_ORIGIN_COUNTRY,
  //DEMOGRAPHICS_TAX_COUNTRY,
  PEER_GROUP_BENCHMARK,
  DIVIDEND_HISTORY,
  DISCLAIMER_SHAREHOLDERS,
  STANDARD_PAGE,
  GENERAL_THEME,
};

export const modules = createSet(all);
export const webModulesSet = {
  STANDARD_PAGE,
  SNAPSHOT,
  GRAPH_INTERACTIVE,
  PROFIT_CALCULATOR,
  PEER_GROUP_BENCHMARK,
  TRADES,
  ORDER_DEPTH,
  COMPANY_INFO,
  DISCLAIMER,
  //HISTORIC_PROFIT_LONG,
  //HISTORIC_PROFIT_SHORT,
  HISTORY_EXPORT,
  DIVIDEND_HISTORY,
  //GRAPH_IMAGE,
  //MINI_GRAPH_INTERACTIVE,
  //GENERIC_SNAPSHOTS,
  SHARE_PERFORMANCE,
  FINANCIAL_CALENDAR,
  COMPANY_DISCLOSURES,
  COMPANY_DISCLOSURES_ARCHIVE,
  //COMPANY_DISCLOSURES_RSS,
};
export const topShareholdersSet = {
  SHAREHOLDERS,
  //SHAREHOLDERS_COUNTRY,
  DISCLAIMER_SHAREHOLDERS,
  //DEMOGRAPHICS_ACCOUNT_TYPE,
  //DEMOGRAPHICS_ORIGIN_COUNTRY,
  //DEMOGRAPHICS_TAX_COUNTRY,
};
export const bondSet = {
  BOND_INFO,
  BOND_QUOTE,
  DISCLAIMER_BOND,
};
export const disclosuresSet = {
  COMPANY_DISCLOSURES,
  COMPANY_DISCLOSURES_ARCHIVE,
  //COMPANY_DISCLOSURES_RSS,
};
export const otherSet = {
  BROKER_STATS,
};

/** Adds component, product, label and name keys if they are not provided.  */
function create(obj: ProductOptions): ProductReturn {
  return {
    get component(): string {
      return this.key.charAt(0).toUpperCase() + this.key.slice(1);
    },
    get product(): string {
      return this.key;
    },
    get name(): string {
      return this.key;
    },
    get path(): string {
      return `/component/${this.key}`;
    },
    get label(): string {
      return toSentenceCase(this.key);
    },
    ...obj,
  };
}

/**
 * Converts a key to a human readable string
 * @param str
 * @example demographicsTaxCountry -> Demographic tax country
 */
function toSentenceCase(str: string) {
  const temp = str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1');
  const result = temp.charAt(0).toUpperCase() + temp.slice(1);
  return result;
}

/** Adds a query utility to a set of products  */
function createSet<T extends Keys>(
  items: {
    [key in T]: ProductReturn;
  },
) {
  return {
    ...items,
    query({ where }) {
      const { query: _, ...rest } = this;
      const _modules = Object.values(rest);
      const result = _modules.find(item => {
        const passed = Object.entries(where).every(([key, value]) => {
          return item[key] ? item[key].toString() === value?.toString() : false;
        });
        return passed;
      });
      return result;
    },
  } as Modules;
}

type Property = {
  key: string;
  value?: string;
};

type ProductOptions = {
  id: number;
  key: string;
  name?: string;
  product?: string;
  component?: string;
  label?: string;
  path?: string;
  properties?: Property[];
  props?: Record<string, string>;
  json?: boolean;
  jsonOptions?: Array<{
    param: string;
    type: string | string[];
    value: string | number | undefined;
    required: boolean;
  }>;
  desc?: string | TemplateStringsArray;
};

type ProductReturn = {
  id: number;
  key: string;
  name: string;
  product: string;
  component: string;
  label: string;
  path: string;
  properties?: Property[];
  props?: Record<string, string>;
  json?: boolean;
  jsonOptions?: Array<{
    param: string;
    type: string | string[];
    value: string | number | undefined;
    required: boolean;
  }>;
  desc?: string | TemplateStringsArray;
};

type Query = {
  where: Partial<ProductOptions>;
};

type QueryFn = (query: Query) => ProductReturn | undefined;

type Keys = keyof typeof all;

type Modules = {
  [key in Keys]: ProductReturn;
} & { query: QueryFn };
